import React from 'react';
// import logo from './logo.svg';
import './App.css';

// import * as Survey from "survey-react";
import { Survey, ReactSurveyModel } from 'survey-react';
import "survey-react/survey.css";
const axios = require('axios').default;



const json = require('./model.json');


function getQueryVariable(variable: any)
{
        var query = window.location.search.substring(1);
        //console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if(pair[0] == variable){return pair[1];}
         }
         return(false);
}

function App({location}: any) {

  const model = new ReactSurveyModel(json);

  
  const session = getQueryVariable("session");
  const stage = getQueryVariable("stage");
  console.log('with session: ', getQueryVariable("session"));
  console.log('with stage: ', getQueryVariable("stage"));  
  console.log('with stage: ', getQueryVariable("stages"));  
  
  const sessionQuestion = model.getQuestionByName("session");
  sessionQuestion.value = session

  const stageQuestion = model.getQuestionByName("stage");
  stageQuestion.value = stage
  
  const onComplete = async (result: any) => {

    const options = {
      method: 'POST',
      headers: { 'Authorization': 'survey01-prod' },
      data: result.data,
      url: 'https://g15fkp1z0h.execute-api.eu-west-1.amazonaws.com/prod/'
    };

    console.log("Complete! ", result);
    const axiosResult = await axios(options)
    console.log('result: ', axiosResult)
  }

  return (
    <div className="App">
      <a href="https://ideab3.it">
        <img src="https://www.ideab3.it/wp-content/uploads/2019/02/237x75.png" width="237" height="75" style={{maxHeight:'75px', height:"auto"}} alt="IDEA B3 Logo"/>
      </a>
      <div style={{width:'80%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'justify'}}>
        <Survey clearInvisibleValues={false} style={{textAlign: 'left'}} model={model} onComplete={onComplete} />
      </div>
    </div>
  );
}

export default App;
